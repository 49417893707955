<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        flat
        dense
        outlined
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense :search="search">
        <template v-slot:item.no="{ item }">
          {{ items.findIndex(x => x === item) + 1 }}
        </template>
        <template v-slot:item.invoiceDate="{ item }">
          {{ formatDate(item.invoiceDate) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "query-link-do",
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Invoice Number",
        value: "invoiceNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Customer",
        value: "customerName",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Invoice Date",
        value: "invoiceDate",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "type",
        value: "type",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    items: [],
    search: "",
  }),

  methods: {
    async getNoLinkDO() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("saleInvoice/queryLinkDO")
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
  },
  mounted() {
    this.getNoLinkDO();
  },
};
</script>

<style></style>
